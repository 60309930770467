import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from "prop-types";

import { useCart } from "context/cartContext";
import { useCurrency } from "context/currencyContext";
import { useCustomer } from "context/customerContext";
import { useWindow } from "context/windowContext";
import { useCategories } from "context/categoriesContext";
import { useBrands } from "context/brandsContext";

import { isEmpty, isNotEmpty } from "utils/helper";

import Button from "components/UI/button";
import Breadcrumbs from "components/UI/breadcrumbs";
import Loader from "components/UI/loader";

import CheckoutStep from "components/UI/Checkout/checkoutStep";
import { GTMCheckoutEvent } from "components/GTM/gtmCheckout";
import { GTMViewCartEvent } from "components/GTM/gtmCart";

const CartContent = lazy(() => import('components/UI/Cart/cartContent'));
const CheckoutCustomer = lazy(() => import('components/UI/Checkout/checkoutCustomer'));
const CheckoutAddress = lazy(() => import('components/UI/Checkout/checkoutAddress'));
const CheckoutPayment = lazy(() => import('components/UI/Checkout/checkoutPayment'));

const CheckoutSteps = (props) => {
    const {
        isCartStep,
        isCustomerStep
    } = props;

    const { currency } = useCurrency();
    const { cart, cartTotal, cartItems, getLocalProduct } = useCart();
    const { customer } = useCustomer();
    const { window, document, location, history } = useWindow();
    const { getCategoryName } = useCategories();
    const { getBrandName } = useBrands();

    const breadcrumbItems = useMemo(() => [{
        name: "Winkelmand",
        isAvailable: isCartStep
    }, {
        name: "Gegevens",
        isAvailable: isCustomerStep && !customer
    }, {
        name: "Adres",
        isAvailable: true
    }, {
        name: "Betaling",
        isAvailable: true
    }], [customer]);
    const [activeStepIndex, setActiveStepIndex] = useState(() => {
        const newStepIndex = breadcrumbItems.findIndex(({ name }) => location.hash.substring(1).includes(name));
        if (newStepIndex === -1) {
            return 0;
        }
        return newStepIndex;
    });
    const [isOrderSubmitting, setIsOrderSubmitting] = useState(false)

    useEffect(() => {
        !activeStepIndex && setActiveStepIndex(breadcrumbItems.indexOf(breadcrumbItems.find(({ isAvailable }) => isAvailable)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeStepIndex])

    const gtmData = useCallback(async () => (
        {
            currency: currency.currency_code,
            value: cartTotal,
            items: await Promise.all(
                cartItems.map(async (item) => {
                    const localProduct = await getLocalProduct(item.product_id);

                    return {
                        item_id: item.product_id,
                        item_name: item.name,
                        discount: +(
                            localProduct.price * item.quantity -
                            localProduct.variants.find(variant => variant.id === item.variant_id).price / (
                                parseInt(
                                    localProduct
                                        .variants
                                        .find(variant => variant.id === item.variant_id)
                                        .option_values[0].label.split("every")[0],
                                    10
                                ) || 1
                            )
                        ).toFixed(2),
                        item_brand: getBrandName(localProduct.brand_id),
                        item_category: getCategoryName(localProduct.categories[0]),
                        item_variant: item.variant_id,
                        price: item.sale_price,
                        quantity: item.quantity,
                    }
                })
            ),
        }
    ), [cartItems, cartTotal]);

    const setActivePrevStep = () => {
        setActiveStepIndex(activeStepIndex => (
            breadcrumbItems.findLastIndex((item, ind) => item.isAvailable && ind < activeStepIndex)
        ));
    }

    useEffect(() => {
        isNotEmpty(cartItems) && isNotEmpty(currency) && GTMCheckoutEvent({
            coupon: "",
            checkout_step: activeStepIndex + 1,
            ...gtmData,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems, currency, activeStepIndex]);

    useEffect(() => {
        isCartStep && isNotEmpty(cartItems) && isNotEmpty(currency) && GTMViewCartEvent(gtmData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems, currency])

    const stepsComponents = useMemo(() => [
        ({ nextStepAction }) => (
            <CartContent
                ActionButtons={() => <>
                    <Button value="Volgende" type="dark" isArrowShow
                            onClick={nextStepAction}/>
                    <Button value="Verder winkelen" className="minicart__close-button" href="/"/>
                </>}/>
        ),
        ({ nextStepAction }) => (
            <CheckoutCustomer nextStepAction={nextStepAction}/>
        ),
        ({ nextStepAction }) => (
            <CheckoutAddress nextStepAction={nextStepAction}/>
        ),
        ({ nextStepAction }) => (
            <CheckoutPayment gtmData={gtmData} setIsOrderSubmitting={setIsOrderSubmitting}
                             nextStepAction={nextStepAction}/>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []);

    useEffect(() => {
        isEmpty(location.hash) && history.replaceState({}, "", `${location.pathname}#${breadcrumbItems[activeStepIndex].name}`);

        // Load Stripe and Chargebee scripts
        const stripeScript = document.createElement('script');
        stripeScript.src = 'https://js.stripe.com/v3/';
        stripeScript.async = true;
        document.head.appendChild(stripeScript);

        const chargebeeScript = document.createElement('script');
        chargebeeScript.src = 'https://js.chargebee.com/v2/chargebee.js';
        chargebeeScript.async = true;
        document.head.appendChild(chargebeeScript);

        return () => {
            // Cleanup when component unmounts
            document.head.removeChild(stripeScript);
            document.head.removeChild(chargebeeScript);
        };
    }, []);

    useEffect(() => {
        if (!cart && !isOrderSubmitting) {
            setTimeout(() => {
                setActiveStepIndex(0)
            }, 1000)
        }
    }, [cart]);

    useEffect(() => {
        const hashchange = () => {
            const newStepIndex = breadcrumbItems.findIndex(({ name }) => name === location.hash.substring(1));
            if (newStepIndex === -1) {
                return;
            }

            if (!breadcrumbItems[newStepIndex].isAvailable) {
                history.back();
                return;
            }

            if (newStepIndex < activeStepIndex) {
                setActivePrevStep();
            }
        }

        window.addEventListener('hashchange', hashchange);
        return () => {
            window.removeEventListener('hashchange', hashchange)
        }
    }, [activeStepIndex]);

    return (
        isEmpty(breadcrumbItems) ? (
            <Loader/>
        ) : (<>
            <Breadcrumbs items={breadcrumbItems} active={activeStepIndex} setActive={setActiveStepIndex}/>
            <Suspense fallback={<Loader/>}>
                {
                    stepsComponents.map((StepsComponent, index) => (
                        breadcrumbItems[index].isAvailable &&
                        <CheckoutStep activeStepIndex={activeStepIndex} currentStepIndex={index} key={index}>
                            <StepsComponent
                                key={index}
                                nextStepAction={() => {
                                    if (index < (stepsComponents.length - 1)) {
                                        setActiveStepIndex(() => {
                                            const activeStepIndex = breadcrumbItems.findIndex(
                                                (item, ind) => item.isAvailable && ind > index
                                            );

                                            history.pushState({}, "", `${location.pathname}#${breadcrumbItems[activeStepIndex].name}`);
                                            return activeStepIndex;
                                        });
                                    }
                                }}
                            />
                        </CheckoutStep>
                    ))
                }
            </Suspense>
        </>)
    );
};

CheckoutSteps.propTypes = {
    isCartStep: PropTypes.bool,
    isCustomerStep: PropTypes.bool
}

CheckoutSteps.defaultProps = {
    isCartStep: true,
    isCustomerStep: true
}

export default CheckoutSteps;
