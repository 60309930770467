import React from 'react';

import CheckoutProvider from "context/checkoutContext";
import CheckoutSteps from "components/UI/Checkout/checkoutSteps";
import StaticPageMeta from "components/UI/staticPageMeta";

const QuestionnaireCheckout = () => {
    return (
        <div className="questionnaire__checkout">
            <StaticPageMeta pageName={`Checkout`}/>
            <CheckoutProvider>
                <div className="checkout__wrapper">
                    <CheckoutSteps isCartStep={false} isCustomerStep={false}/>
                </div>
            </CheckoutProvider>
        </div>
    );
};

export default QuestionnaireCheckout;
