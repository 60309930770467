import React from 'react';
import PropTypes from "prop-types";

const CheckoutStep = props => {
    const { activeStepIndex, currentStepIndex, children } = props;

    return (
        <div className={"checkout__step" + (activeStepIndex === currentStepIndex ? " checkout__step--active" : "")}
             data-step={currentStepIndex}>
            {
                (activeStepIndex >= currentStepIndex) && (
                    children
                )
            }
        </div>
    );
};

CheckoutStep.propTypes = {
    activeStepIndex: PropTypes.number.isRequired,
    currentStepIndex: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
}

export default CheckoutStep;
