import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

import { isEmpty } from "utils/helper";

import { useWindow } from "context/windowContext";

const CheckoutContext = createContext({});

export const useCheckout = () => {
    return useContext(CheckoutContext);
};

const CheckoutProvider = ({ children }) => {
    const { localStorage } = useWindow();

    const [order, setOrder] = useState(JSON.parse(localStorage.getItem('order')) ?? {});
    const [shippingZones, setShippingZones] = useState([]);
    const [countries, setCountries] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);

    useEffect(() => {
        localStorage.setItem('order', JSON.stringify(order));
    }, [order]);

    const updateOrder = (key, value) => {
        setOrder(oldOrder => ({
            ...oldOrder,
            [key]: value
        }))
    }

    const getShippingZonesRequest = () => {
        axios
            .get("/api/v2/shipping/zones")
            .then(({ data: zones }) => setShippingZones(zones))
    }

    const getCountriesRequest = () => {
        axios
            .get("/api/v2/countries")
            .then(({ data: countries }) => setCountries(countries))
    }

    const getOrderStatusesRequest = () => {
        axios
            .get("/api/v2/order_statuses")
            .then(({ data: orderStatuses }) => setOrderStatuses(orderStatuses))
    }

    useEffect(() => {
        isEmpty(shippingZones) && getShippingZonesRequest();
        isEmpty(countries) && getCountriesRequest();
        isEmpty(orderStatuses) && getOrderStatusesRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isAddressValid = ({ target }) => {
        const inputs = Array.from(target.querySelectorAll("input"));

        const isFormValid = inputs.every(
            input => {
                return (
                    input.type === "checkbox" ||
                    input.name === "building" ||
                    input.getAttribute("data-is-valid") !== "false"
                )
            }
        );

        if (!isFormValid) {
            // toast.error('Address form is invalid!');
            return false;
        }
        return true;
    }

    return (
        <CheckoutContext.Provider
            value={{
                order,
                updateOrder,
                orderStatuses,
                shippingZones,
                countries,
                isAddressValid,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};

export default CheckoutProvider;
