import React, { useState } from "react";

import { isNotEmpty } from "utils/helper";

import Main from "components/Layout/main";

import QuestionnaireCheckout from "components/UI/Questionnaire/questionnaireCheckout";
import QuestionnaireHeader from "components/UI/Questionnaire/questionnaireHeader";
import QuestionnaireTemplate from "components/UI/Questionnaire/questionnaireTemplate";
import QuestionnaireWelcome from "components/UI/Questionnaire/questionnaireWelcome";

import Loader from "components/UI/loader";

import "styles/checkout-page.scss";
import "styles/questionnaire.scss";

const Questionnaire = (props) => {
    return (<>
        <QuestionnaireHeader/>
        <Main isFooter={false} isHeader={false} className="questionnaire">
            <QuestionnaireWrapper {...props}/>
        </Main>
    </>);
}

const QuestionnaireWrapper = (props) => {
    const { welcome_screens } = props.pageContext.questions;

    const [questionnaireComponent, setQuestionnaireComponent] = useState(
        isNotEmpty(welcome_screens) ?
            'welcome_screen' :
            'questionnaire'
    );


    const setWelcomeScreenActive = () => setQuestionnaireComponent('welcome_screen');
    const setQuestionnaireActive = () => setQuestionnaireComponent('questionnaire');
    const setCheckoutActive = () => setQuestionnaireComponent('checkout');

    return (<>
        {{
            'welcome_screen': (<>
                {isNotEmpty(welcome_screens) &&
                    <QuestionnaireWelcome
                        setQuestionnaireActive={setQuestionnaireActive}
                        data={welcome_screens[0]}
                    />
                }
            </>),
            'questionnaire': (
                <QuestionnaireTemplate
                    setWelcomeScreenActive={setWelcomeScreenActive}
                    setCheckoutActive={setCheckoutActive}
                    {...props}
                />
            ),
            'checkout': <QuestionnaireCheckout/>,
        }[questionnaireComponent] || <Loader/>}
    </>)
}

export default Questionnaire;
