import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Button from "components/UI/button";
import Image from "components/UI/image";
import Slider from "components/UI/slider";
import StaticPageMeta from "components/UI/staticPageMeta";
import { clearLocalStorageBeforeQuestionnaire } from "components/UI/Questionnaire/questionnaireTemplate";

import { useWindow } from "context/windowContext";
import { isNotEmpty } from "utils/helper";

export const questionnaireWelcomeQuery = graphql`
  query questionnaireWelcomeQuery {
    allContentfulSlider(filter: {type: {eq: "questionnaire-welcome-slider"}}) {
      nodes {
        id
        location
        name
        slideCount
        sliderTitle
        slides {
          id
          name
          slideImage {
            file {
              contentType
              url
            }
            gatsbyImageData(
              placeholder: NONE,
              formats: [AUTO, WEBP, AVIF],
            )
            title
          }
          superscript
          title
          subtext
        }
      }
    }
  }
`;

const QuestionnaireWelcome = props => {
    const { data, setQuestionnaireActive } = props;
    const { properties, title } = data;

    const { location } = useWindow();
    const { nodes: sliders } = useStaticQuery(questionnaireWelcomeQuery).allContentfulSlider;

    const slider = sliders.find(slide => location.pathname.includes(slide.location));

    const sliderConfig = {
        spaceBetween: 16,
        slidesPerView: 1,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
        },
        breakpoints: {
            580: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 25,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 28,
            },
            1200: {
                slidesPerView: 4,
            }
        },
        className: "swiper-container questionnaire__welcome-slider",
    };

    useEffect(() => {
        if (isNotEmpty(location.hash)) {
            setQuestionnaireActive();
        } else {
            clearLocalStorageBeforeQuestionnaire();
        }
    }, []);

    return (
        <div className="questionnaire__welcome">
            <StaticPageMeta pageName={`Questionnaire Welcome`}/>
            <h2 className="questionnaire__welcome-description">
                {properties.description}
            </h2>
            <h1 className="questionnaire__welcome-title">
                {title}
            </h1>
            <Slider config={sliderConfig}>
                {
                    isNotEmpty(slider?.slides) && (
                        slider.slides.map((slide, key) => (
                            <div className="questionnaire__welcome-slide slide" key={key}>
                                <div className="slide__image-container">
                                    <Image image={slide.slideImage} className="slide__image" objectFit="contain"/>
                                    <p className="typography__sub-text slide__image-title">{slide.superscript}</p>
                                </div>
                                <p className="slide__title">{slide.title}</p>
                                <p className="slide__description">{slide.subtext}</p>
                            </div>
                        ))
                    )
                }
            </Slider>
            {
                properties.show_button && (
                    <Button
                        className="questionnaire__welcome-button"
                        onClick={setQuestionnaireActive}
                        value={properties.button_text}
                    />
                )
            }
        </div>
    );
};

export default QuestionnaireWelcome;
